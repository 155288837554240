.events-row {
  display: flex;
  flex-direction: row;
  width: 1500px;
}

.event-col {
  width: 500px;
  max-width: none;
  margin-top: -20px;
}

.moral-budget-title {
  font-family: 'Space Mono 700';
  font-style: normal;
  font-size: 48px;
  text-transform: uppercase;
  color: #a559ec;
  text-align: left;
  margin-left: 30px;
  margin-top: 460px;
}

.moral-budget-text {
  width: 500px;
  color: black;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  margin-left: 30px;
  column-gap: 100px;
}

.orientation-title {
  font-family: 'Space Mono 700';
  font-style: normal;
  font-size: 48px;

  text-transform: uppercase;
  color: #187dff;
  text-align: left;

  margin-top: 60px;
}

.orientation-text {
  width: 400px;
  color: black;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  font-weight: 300;

  column-gap: 100px;
}

.inclined-image {
  width: 1000px;
  aspect-ratio: 1.7/1;
  position: relative;
  left: -500px;
  top: 70px;
}

.shiftLeft {
  margin-left: 20px;
}

.shiftleft3 {
  margin-right: 50px;
}

.inclined-img-container {
  width: 100px;
}

@media (max-width: 650px) or (max-device-width: 650px) {
  .events-2-container {
    display: flex;
    flex-direction: column;
    height: 300vw;
  }

  .events-2-section-header-top {
    color: #187dff;
    font-size: 12vw;
    font-family: 'Space Mono 700';
    text-transform: uppercase;
    word-wrap: break-word;
    text-align: center;
    line-height: 8vw;
  }

  .events-2-section-header {
    color: #187dff;
    font-size: 9vw;
    font-family: 'Space Mono 700';
    text-transform: uppercase;
    word-wrap: break-word;
    margin-left: 7vw;
    margin-top: 20vw;
    line-height: 9vw;
  }

  .events-2-text {
    color: black;
    font-size: 4.5vw;
    font-family: 'Outfit 300';
    line-height: 6.7vw;
    word-wrap: break-word;
    height: auto;
    width: auto;
    margin-left: 7vw;
    margin-right: 7vw;
  }
}
