.all-projects-container {
  position: relative;
  display: flex;
  max-width: unset;
  align-items: center;
}

.all-projects-header {
  display: flex;
  height: 100vh;
  justify-content: flex-start;
  width: -moz-fit-content;
  width: fit-content;
}

.all-projects-header .projects-title {
  color: #187dff;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
}

.all-projects {
  display: flex;
  height: 80vh;
  width: 100%;
  justify-content: space-evenly;
}

.project-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.search-bar {
  display: none;
}

@media only screen and (max-width: 650px), (max-device-width: 650px) {
  .all-projects-container {
    width: 100vw !important;
    flex-direction: column;
  }

  .all-projects-header .projects-title {
    writing-mode: horizontal-tb;
    transform: unset;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
    margin-bottom: 10%;
    font-size: 15vw;
  }

  .all-projects-header,
  .projects-title {
    width: 100%;
    height: unset;
  }

  .all-projects {
    flex-direction: column;
    justify-content: flex-start;
    height: unset;
  }

  .project-col {
    align-items: center;
  }

  .search-bar {
    display: block;
    z-index: 1;
    margin-right: auto;
    margin-left: 3vw;
    width: 70vw;
    height: 4.5vh;
    margin-bottom: 5vh;
    font-size: 2vh;
  }
}

.search-icon {
  border: 1px solid #555;
  display: block;
  margin-top: auto;
  padding: 10px 4px 10px 80px;
  background: transparent no-repeat 20px url(./search.svg);
}
