.vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: 0 10px 10px 10px;
}

.vertical-div {
  width: 50px;
}

.text-div-inclusion {
  padding: 0 50px 0 100px;
  width: 650px;
  margin-top: 17vw; /*240px;*/
  display: flex;
  flex-direction: column;
}

.outline-inclusion {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 90px;
  line-height: 90px;
  padding: 0 10px 10px 10px;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffbf3c;
}
.text-div-inclusion {
  padding: 0 50px 0 100px;
  margin-top: 200px;
  width: 650px;
  display: flex;
  flex-direction: column;
}

@media (max-device-width: 650px) or (max-width: 650px) {
  .inclusion-container {
    padding: 5%;
    height: 170vw;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .inclusion-outline {
    position: relative;
    margin-right: 5vw;
    writing-mode: horizontal-tb;
    font-size: 9vw;
    color: white;
    -webkit-text-stroke-width: 0.2vw;
    -webkit-text-stroke-color: #ffbf3c;
  }
}
