.vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 90px;
}

.outline {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 90px;
  line-height: 90px;
  padding: 0 10px 10px 10px;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #6fcf97;
  /* text-shadow: -1px 0 #6fcf97, 0 1px #6fcf97, 1px 0 #6fcf97, 0 -1px #6fcf97; */
}

h2 {
  color: white;
  /* -webkit-text-stroke-width: 1px; */
  -webkit-text-stroke-color: #dfdfdf;
}

.text-div-diversity {
  padding: 0 50px 0 100px;
  margin-top: 100px;
  width: 650px;
  display: flex;
  flex-direction: column;
}

.container {
  width: 750px;
  max-width: none;
  display: flex;
  flex-direction: row;
}

.vertical-div {
  width: 50px;
}

.text-div {
  margin: 5%;
  padding: 5%;
}

.culture-text {
  font-size: 22px;
  line-height: 10px;
}

@media (max-device-width: 650px) or (max-width: 650px) {
  .diversity-container {
    padding: 5%;
    height: 90vw;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .diversity-outline {
    position: relative;
    right: 6vw;
    margin-right: 5vw;
    writing-mode: horizontal-tb;
    font-size: 10vw;
    color: white;
    -webkit-text-stroke-width: 0.2vw;
    -webkit-text-stroke-color: #6fcf97;
  }
}
