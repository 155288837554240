a {
  text-decoration: none;
  color: #ffffff;
}

.footer-container {
  background-color: #187dff;
  text-align: left;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 10vw;
  cursor: pointer;
}

.title {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: #187dff;
  padding-top: 0;
  padding: 1vw;
  font-size: max(7.25vw, 64px);
  background-color: white;
  width: fit-content;
}

.title-backg {
  z-index: -10;
  height: fit-content;
  width: fit-content;
  padding-top: 3vw;
}

.left-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-bar-mobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.spacing {
  margin-left: 2vw;
  margin-bottom: 2vw;
}

.footer-text {
  height: 30px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 30px;
  color: #ffffff;
  padding-left: 15%;
  margin: 5%;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-pages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 3vw;
}

.sherm {
  padding: 5%;
  margin-top: 0;
}

.logo-placement {
  width: 12vh;
  height: 14vh;
}

.social-icons-website {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  height: 100px;
}

.social-icons-mobile {
  display: flex;
  flex-direction: column-reverse;
  margin-right: 3vw;
}

.icon-columns {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.icon-rows {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.right-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bot-box-mobile {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

.divider-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.divider-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.up-icon {
  flex-shrink: 0;
  margin-left: 3vw;
  background: #ffbf3c;
  border: 1px solid black;
  box-shadow: -0.5rem 0.6rem black;
}

.up-icon-mobile {
  width: 15vw;
  height: 15vw;
  flex-shrink: 0;
  margin-right: 2vw;
  background: #ffbf3c;
  border: 1px solid black;
  box-shadow: -0.8rem 0.8rem black;
  cursor: pointer;
}

.footer-two {
  background-color: orange;
  align-items: right;
}

.rectangle {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: black;
}

.rectangleLogo {
  margin-left: 50px;
  margin-top: 20px;
  width: 90px;
  height: 90px;
  background: black;
}

.teams-icon {
  padding-left: 3vw;
  height: fit-content;
  margin-bottom: 3vh;
}

.teams-insta-icon {
  padding-left: 3vw;
  height: fit-content;
  margin-bottom: 0;
}

.website-col {
  margin-bottom: 2vh;
  margin-left: 1vw;
}
