.culture-text-box {
  width: 500px;
}

.culture-text {
  font-family: 'Outfit 300';
  font-size: 24px;
  line-height: 30px;
}

.nospacing {
  margin: 0%;
  padding: 0%;
}

.culture-outline {
  -webkit-text-stroke: 1px #dfdfdf;
  font-size: 100px;
}

@media (max-width: 650px) or (max-device-width: 650px) {
  .culture-text {
    color: #111111;
    font-size: 4vw;
    line-height: 6vw;
    word-wrap: break-word;
    padding-top: 7%;
  }

  .culture-outline {
    -webkit-text-stroke: 0.3vw #dfdfdf;
    font-size: 20vw;
    text-align: left;
  }

  .culture-div {
    padding-top: 5%;
  }
}
