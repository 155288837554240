/* Move to public folder */
.text-no-fill {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
}

.events-container {
  width: 1000px;
  max-width: none;
}

.events-top {
  color: #a2a2a2;
  font-family: 'Space Mono';
  font-size: 20px;
  line-height: 30px;
  margin-top: 32px;
}

.events-header {
  font-family: 'Space Mono';
  font-size: 250px;
  line-height: 210px;
  margin-top: 10px;
  color: #ff6660;
}

.events-picture-container {
  font-size: 982px;
  line-height: 700px;
}

.retreat-title {
  font-family: 'Space Mono 700';
  font-style: normal;
  font-size: 48px;
  margin-top: 320px;
  text-transform: uppercase;
  color: #a559ec;
  text-align: left;
}

.retreat-text {
  width: 500px;
  height: 10%;
  /* color: black; */
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  font-weight: 300;
}

.events-quotation-mark {
  color: #187dff;
  font-family: 'Space Mono 700';
  font-size: 90px;
  line-height: 75%;
}

.events-our-values {
  color: #ffbf3c;
  font-size: 25px;
  line-height: 100%;
  font-family: 'Space Mono';
  margin-right: 3%;
}

.events-we-are {
  color: #ffbf3c;
  font-size: 225px;
  line-height: 77%;
  font-family: 'Space Mono 700';
  word-spacing: -75px;
}

.img-formatting {
  width: 300px;
  height: 450px;
  object-fit: cover;
  border: 1px solid;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: -35%;
}

.for-padding {
  padding: 2em;
  margin-top: 20%;
}

.retreat {
  margin-top: 10vw;
}

.eventsTitle {
  box-sizing: border-box;
  width: 1103px;
  height: 230px;
  left: 6191px;
  top: 100px;

  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 300px;
  line-height: 230px;
  /* identical to box height, or 77% */

  text-transform: uppercase;

  /* Core/HRDWRE Red */

  border: 2px solid #ff6660;
}

@media (max-device-width: 650px) or (max-width: 650px) {
  .text-no-fill {
    -webkit-text-stroke-width: 0.25vh;
  }

  .events-container {
    display: flex;
    flex-direction: column;
    height: 360vw;
    width: auto;
    background-color: #7b61ff;
  }

  .events-top-container {
    display: flex;
    height: 160vw;
    width: auto;
  }

  .events-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50vw;
  }

  .events-images-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50vw;
  }

  .events-header-text {
    color: #7b61ff;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    font-family: 'Space Mono 700';
    font-size: 34vw;
    -webkit-text-stroke-width: 0.3vw;
    -webkit-text-stroke-color: #ffffff;
    margin-top: 15vw;
    margin-left: 8vw;
    line-height: 26vw;
  }

  .events-header-subtext {
    color: #ffffff;
    font-family: 'Space Mono 400';
    font-size: 5vw;
    line-height: 7vw;
    margin-left: 7vw;
    margin-top: 7vw;
  }

  .events-image {
    object-fit: contain;
    width: 45vw;
    height: 67.5vw;
    background: linear-gradient(0deg, white 0%, white 100%);
    border: 1px #111111 solid;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    margin-right: 8vw;
    margin-top: 12.5vw;
  }

  .events-section-header {
    color: #ffffff;
    font-size: 9vw;
    font-family: 'Space Mono 700';
    text-transform: uppercase;
    word-wrap: break-word;
    margin-left: 7vw;
    margin-top: 20vw;
    line-height: 9vw;
  }

  .events-section-header-bottom {
    color: #ffffff;
    font-size: 12vw;
    font-family: 'Space Mono 700';
    text-transform: uppercase;
    text-align: center;
    margin-top: auto;
    line-height: 10vw;
  }

  .events-text {
    color: white;
    font-size: 4.5vw;
    font-family: 'Outfit 300';
    line-height: 6.7vw;
    word-wrap: break-word;
    height: auto;
    width: auto;
    margin-left: 7vw;
    margin-right: 7vw;
  }
}
