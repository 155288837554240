.pp-fp-projects-page-container {
  min-width: 1007px;
  max-width: 80vw;
}

.pp-fp-project {
  padding-top: 8rem;
}

.pp-fp-projects-bg {
  margin-top: 10rem;
}

@media (max-width: 1500px) {
  .pp-fp-projects-page-container {
    margin-right: 25vw;
  }
}

.featured-pp-fp-projects {
  display: flex;
  width: 90vw;
}

.pp-fp-projects-title {
  color: #187dff;
  font-weight: 700;
  font-size: 6vh;
}

#pp-fp-projects-navbar {
  padding-top: 0.5rem;
  padding-right: 9.25rem;
}

.pp-fp-projects-text {
  color: #000;
  font-size: 2.5vh;
  margin-top: 5%;
  max-width: 400px;
}

.featured-pp-fp-projects-text {
  margin-left: 4%;
  width: min-content;
  min-width: 396px;
  margin-top: -3%;
}

.pp-fp-projects-back img {
  transform: rotate(180deg);
}

.pp-fp-projects-button {
  background: #ffffff;
  border: 1px solid black;
  width: 10vh;
  height: 10vh;
  box-shadow: -0.5rem 0.6rem black;
  margin-top: 10vh;
}

.pp-fp-projects-forward {
  margin-right: 40px;
}

.pp-fp-project {
  display: flex;
  margin-top: 10px;
}

.pp-fp-project-text h1 {
  font-family: 'Space Mono 700';
  font-size: 7vh;
}

.pp-fp-project-team {
  font-family: 'Space Mono';
  font-size: 3vh;
  color: #000;
  text-align: left;
  text-transform: none;
  font-weight: 400;
}

.pp-fp-desc {
  font-family: 'Outfit';
  font-size: 2vh;
  font-weight: 300;
  line-height: 2vh;
  letter-spacing: 0em;
  text-align: left;
  width: fit-content;
  margin-right: 80vw;
  margin-top: 3vh;
}

.pp-fp-project-text p {
  max-width: 250px;
  font-size: 2vh;
}

.view-pp-fp-project-button {
  color: black;
  background-color: white;
  border: 1px solid #111111;
  box-shadow: -0.5rem 0.6rem;
  font-family: 'Space Mono';
  font-size: 3vh;
  position: relative;
  width: 10vw;
  margin-left: 5px;
  margin-top: 10vh;
}

/* Hover effect will only effect mouse devices */
@media (hover: hover), (-moz-touch-enabled: 0) {
  .hoverButton:hover {
    box-shadow: 0 0;
  }
}

.view-pp-fp-project-button-2 {
  color: black;
  background-color: white;
  border: 1px solid #111111;
  box-shadow: -0.5rem 0.6rem;
  font-family: 'Space Mono';
  font-size: 2vh;
  position: relative;
  height: 10vw;
  width: 35vw;
  margin-left: 5px;
}

.pp-fp-projects {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 40vw;
  height: 56vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.pp-fp-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pp-fp-project-text {
  margin-left: 5px;
}

.view-pp-fp-project-div-2 {
  justify-content: center;
}

@media only screen and (max-width: 650px), (max-device-width: 650px) {
  .featured-pp-fp-projects {
    width: unset;
    margin-bottom: 50px;
  }

  .pp-fp-project-text {
    width: 95vw;
  }

  .pp-fp-projects-forward {
    margin-right: 20vw;
  }

  .pp-fp-projects-bg {
    background-color: #187dff;
    margin: 0px;
    padding: 0px;
    min-width: 102vw;
    margin-left: -2vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 5vw;
    padding-top: 15vh;
    padding-bottom: 5vh;
  }

  .pp-fp-projects-buttons {
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
  }

  .pp-fp-projects-page-container {
    min-width: unset;
    width: unset;
  }

  .pp-fp-projects-forward img {
    transform: rotate(270deg);
  }
  .pp-fp-projects-back img {
    transform: rotate(90deg);
  }

  .featured-pp-fp-projects {
    flex-direction: column;
  }

  .pp-fp-projects {
    height: auto;
  }

  .pp-fp-project {
    flex-direction: column;
    height: auto;
  }

  .featured-pp-fp-projects-text {
    width: unset;
  }

  .pp-fp-projects-text {
    max-width: unset;
    color: white;
  }

  .pp-fp-projects-mobile {
    display: block;
    margin-right: auto;
  }

  .featured-pp-fp-project-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 10%;
    width: 95vw;
  }

  .pp-fp-project-team {
    margin-top: -10%;
    width: 33vw;
    height: 10vh;
  }

  .pp-fp-project-name {
    font-size: 8vw;
  }

  .pp-fp-project-text p {
    max-width: unset;
    margin: 5% 10%;
  }

  .view-pp-fp-project-button {
    margin-top: 0;
    margin-bottom: 5%;
  }

  .view-pp-fp-project-button-2 {
    margin-top: 0;
    margin-bottom: 5%;
    display: block;
  }

  .view-pp-fp-project-div {
    display: flex;
    justify-content: center;
  }

  .pp-fp-projects-title {
    font-size: 6vh;
    color: #ffffff;
  }

  .pp-fp-projects-button {
    width: 120px;
    height: 120px;
  }

  .pp-fp-projects-button-2 {
    background: #ffffff;
    border: 1px solid black;
    box-shadow: -0.5rem 0.6rem black;
    width: 12vw;
    height: 12vw;
    display: block;
  }

  .pp-fp-navbar-style {
    position: fixed;
    left: 90%;
    transform: translateX(-50%);
    margin-left: 0;
  }

  .image-container {
    width: 100vw;
    height: 25vh;
    object-fit: contain;
  }

  .view-pp-fp-project-button {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    padding: 10px 30px;
  }

  .view-pp-fp-project-button-2 {
    margin: 0;
    padding: 10px 30px;
  }

  .view-pp-fp-project-div-2 {
    display: flex;
    justify-content: center;
  }
}

.mobile-view-pt {
  margin-top: 2vh;
  display: flex;
  margin-left: 10vw;
}
