.all-hands-title {
  font-family: "Space Mono 700";
  font-style: normal;
  font-size: 48px;
  margin-top: 200px;
  text-transform: uppercase;
  color: #187dff;
  text-align: left;
}

.all-hands-text {
  width: 400px;
  color: black;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  font-weight: 300;

  column-gap: 100px;
}


.all-hands-div {
  width: 400px;
  margin: 0 50px 0 50px;
}

