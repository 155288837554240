.h-25 {
  height: 25%;
}

.h-75 {
  height: 75%;
}

.left-color {
  background-color: #1477f8;
}

.position-title {
  /* Text style */
  font-family: 'Space Mono 700';
  color: white;
  text-transform: uppercase;
  /* Text sizing */
  line-height: 105%;
  inline-size: min-content;
  margin: 0;
}

.position-type {
  color: transparent;
  -webkit-text-stroke: 0.15vw white;
  line-height: 100%;
}

.position-summary {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6vw;
  line-height: 140%;
}

.position-info a {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5vw;
}

.fw-500 {
  font-weight: 500 !important;
}

.position-info li {
  font-size: 1.4vw;
  font-family: 'Outfit';
  font-weight: 300;
  line-height: 145%;
}

.position-info ul {
  padding-bottom: 5%;
  list-style: none;
}

.position-info ul li::before {
  content: '\2022';
  color: #1477f8;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.position-info h3 {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  margin: 0;
}

.position-info h4 {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-size: 1.7vw;
  margin: 0;
}

.position-info h5 {
  color: rgba(17, 17, 17, 0.7);
  font-family: 'Outfit';
  font-weight: 300;
  font-size: 1.2vw;
  margin-bottom: 2%;
}

.link-copied-toast {
  top: 1%;
  right: 2em;
}

.blue-text {
  color: #1477f8;
}

.blue-text:hover {
  color: #1477f8;
}

h1.position-title {
  width: 100%;
  word-wrap: break-word;
}

@media (max-width: 650px) or (max-device-width: 650px) {
  .header-navbar {
    position: fixed;
    left: 90vw;
    top: 5vw;
    transform: translateX(-50%);
  }

  .position-container {
    display: flex;
    flex-direction: column;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    height: 150vw;
    background-color: #1477f8;
  }

  .position-title {
    font-size: 13vw;
    line-height: 16vw;
    margin-left: 5vw;
    margin-top: 25vw;
    width: 90vw;
    word-wrap: break-word;
  }

  .position-team {
    font-size: 13vw;
    line-height: 17vw;
    margin-left: 5vw;
    width: 90vw;
    word-wrap: break-word;
    color: transparent;
    -webkit-text-stroke: 0.15vw white;
    font-family: 'Space Mono 700';
    text-transform: uppercase;
  }

  .header-button-container {
    display: flex;
    margin-top: auto;
    margin-bottom: 17vw;
    margin-left: 5vw;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    height: 245vw;
  }

  .blue-text {
    margin-top: 5vw;
    margin-left: 5vw;
    font-size: 3.75vw;
    font-family: 'Space Mono';
    font-weight: 400;
  }

  .info-section-text {
    font-size: 4vw;
    line-height: 6vw;
    font-family: 'Outfit';
    font-weight: 300;
    margin: 5vw;
  }

  .info-section-header {
    font-size: 4vw;
    font-family: 'Outfit';
    font-weight: bold;
    margin-top: 3vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .info-list-item {
    font-size: 3.75vw;
    line-height: 5vw;
    font-family: 'Outfit';
    font-weight: 300;
    margin: 5vw;
  }

  .bottom-container {
    display: flex;
  }

  .time-container {
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin-left: 5vw;
  }

  .time-section-header {
    font-size: 3.25vw;
    font-family: 'Outfit';
    font-weight: 300;
    color: '#111111B2';
    opacity: 70%;
  }

  .time-section-text {
    font-size: 3.75vw;
    font-family: 'Outfit';
    font-weight: 400;
  }

  .header-apply-button {
    background-color: #ffbf3c;
    padding: 4vw 16vw;
    font-size: 4vw;
    font-family: 'Space Mono';
    color: black;
    box-shadow: -0.5rem 0.6rem black;
  }

  .header-share-button {
    background-color: white;
    padding: 4vw 8vw;
    font-size: 4vw;
    font-family: 'Space Mono';
    color: black;
    box-shadow: -0.5rem 0.6rem black;
    margin-left: 5vw;
  }
}
