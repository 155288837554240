.management-header {
  width: 100vw;
  height: 100%;
  background: #187dff;
}

.header-wrapper {
  overflow-y: hidden;
  height: 20vw;
  width: 100vw;
}

.header-title {
  z-index: -10;
  height: fit-content;
  width: fit-content;
  padding-top: 9vw;
  margin-left: 3vw;
}

.header-text {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: #187dff;
  padding-top: 0;
  padding: 1vw;
  font-size: max(7.25vw, 64px);
  background-color: white;
}

.mobile-management-header {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #187dff;
}

.mobile-header-wrapper {
  overflow-y: hidden;
  height: 16vw;
  width: 100vw;
}

.mobile-header-title {
  height: fit-content;
  width: fit-content;
  padding-top: 3vw;
  padding-bottom: 3vw;
  margin-left: 3vw;
}

.mobile-header-text {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: #187dff;
  padding-top: 0;
  padding: 1vw;
  font-size: max(10vw, 64px);
  background-color: white;
}

.info-section {
  width: 35vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.info-pic {
  width: 30vw;
  height: 30vw;
}

.text-row {
  margin-top: 10vh;
  margin-bottom: 0vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.roles-banner {
  width: 15vw;
  margin-right: 5vw;
  height: max(76.8vw, 1100px);
  /* left: 100px;
    top: 1480px; */
  background: linear-gradient(
    180deg,
    #ffbf3c 8.33%,
    #7b61ff 28.13%,
    #6fcf97 50.52%,
    #ff0000 72.92%,
    #1477f8 90.62%
  );
}

.roles-title {
  margin-top: 10vh;
  margin-left: 0vw;
}

.roles-writing {
  margin-top: 5vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: space-between;
}

.roles-section {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 5vh;
}

.roles {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.roles-desc {
  width: auto;
  line-height: normal;
}

#mobile-management {
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: -6vw;
}

#management {
  padding-left: 10vw;
  padding-right: 10vw;
}

@media (max-width: 650px), (max-device-width: 650px) {
  .info-pic {
    width: 80vw;
    height: 80vw;
  }
}
