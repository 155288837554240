.management-header {
  width: 100vw;
  height: 100%;
  background: #187dff;
}

.header-wrapper {
  overflow-y: hidden;
  height: 20vw;
  width: 100vw;
}

.header-title {
  z-index: -10;
  height: fit-content;
  width: fit-content;
  padding-top: 9vw;
  margin-left: 3vw;
}

.header-text {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: #187dff;
  padding-top: 0;
  padding: 1vw;
  font-size: max(7.25vw, 64px);
  background-color: white;
}

.mobile-management-header {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #187dff;
}

.mobile-header-wrapper {
  overflow-y: hidden;
  height: 16vw;
  width: 100vw;
}

.mobile-header-title {
  height: fit-content;
  width: fit-content;
  padding-top: 3vw;
  padding-bottom: 3vw;
  margin-left: 3vw;
}

.mobile-header-text {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: #187dff;
  padding-top: 0;
  padding: 1vw;
  font-size: max(10vw, 64px);
  background-color: white;
}

.info-section {
  width: 40vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.info-section-mobile {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.info-pic {
  width: min(30vw, 40vh);
  height: min(39.74vw, 52.98vh);
}

.common-info-pic {
  width: 35vw;
  height: 25vw;
}

.feature-info-pic {
  width: 42.22vw;
  height: auto;
  object-fit: cover;
}

.text-row {
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-row-mobile {
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.reverse {
  flex-direction: row-reverse;
}

.roles-banner {
  width: 20px;
  margin-right: 10px;
  height: 100%;
  margin-bottom: 10px;
  /* left: 100px;
    top: 1480px; */
  background: linear-gradient(
    180deg,
    #ffbf3c 8.33%,
    #7b61ff 28.13%,
    #6fcf97 50.52%,
    #ff0000 72.92%,
    #1477f8 90.62%
  );
}

.roles-banner-color {
  width: 15vh;
  margin-right: 60px;
  flex: 1 1 auto;
}

.roles-title {
  margin-left: 0vw;
}

.roles-writing {
  margin-top: 5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.roles-container {
  margin-top: 5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.paragraph-title {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: black;
  font-size: max(4.7vw, 30px);
  line-height: max(5.2vw, 35px);
}

.paragraph-title-mobile {
  font-family: 'Space Mono 700';
  text-transform: capitalize;
  color: black;
  margin-top: 10;
  font-size: max(5vw, 26px);
  line-height: max(12vw, 40px);
}

.role-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-block {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.paragraph-text {
  font-family: 'Outfit';
  color: black;
  font-size: max(1.7vw, 18px);
  line-height: max(2vw, 20px);
}

.paragraph-text-mobile {
  font-family: 'Outfit';
  color: black;
  font-size: max(3vw, 18px);
  line-height: max(4.5vw, 22px);
}

.paragraph-subtitle {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: max(1.9vw, 20px);
  line-height: max(2.84vw, 25px);
  color: black;
}

.paragraph-subtitle-mobile {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: max(4vw, 20px);
  line-height: max(6vw, 26px);
  color: black;
}

.roles-section {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.roles {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.roles-desc {
  width: auto;
  line-height: 1.6;
}

#mobile-management {
  padding-left: 3vw;
  padding-right: 3vw;
  margin-top: -6vw;
}

#management {
  padding-left: 10vw;
  padding-right: 10vw;
}

.feature-button {
  overflow: hidden;
  padding: 1vw;
  width: 40vw;
  margin-right: 0;
}

.feature-link {
  margin-top: min(5vh, 5vw);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media (min-width: 651px) or (min-device-width: 651px) {
  .reverse {
    align-items: flex-end;
  }
}

@media (max-width: 650px) or (max-device-width: 650px) {
  .common-info-pic {
    width: 100%;
    height: 66%;
  }

  .feature-info-pic {
    width: 100%;
    object-fit: cover;
    margin-bottom: 5vw;
  }
  .feature-button {
    overflow: hidden;
    padding: 1vw;
    width: 50vw;
  }
  .feature-link {
    width: 50%;
    height: auto;
    margin-left: auto;
    justify-content: end;
  }
  .text-row-mobile-feature {
    margin-top: 10vw;
    margin-bottom: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
