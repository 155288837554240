.heading-text {
  font-family: 'Space Mono';
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 700;
  color: black;
  text-align: left;
  white-space: nowrap;
}

.headingTwo-text {
  font-family: 'Space Mono';
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 700;
  color: black;
  text-align: left;
}

.belong-here {
  padding: 1px;
  color: black;
  text-align: left;
  position: relative;
  top: 1px;
  width: 300px;
  height: 400px;
}

.thrive-together {
  display: flex;
  flex-direction: column;
  padding: 1px;
  width: 300px;
  height: 400px;
}

.paragraphs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 400px;
  padding-top: 75px;
}

.color-block {
  text-align: center;
  position: relative;
}

.graphic {
  height: 900px;
  width: auto;
}

#belong-here-container {
  height: 800px;
  width: 1500px;
  max-width: none;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -550px;
}

@media (max-device-width: 650px) or (max-width: 650px) {
  .belong-here-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 200vw;
    padding: 5%;
    margin-bottom: 30vw;
  }

  .heading-text {
    color: #187dff;
    font-size: 9vw;
    line-height: 10vw;
    padding-top: 10%;
    white-space: nowrap;
  }

  .headingTwo-text {
    color: #187dff;
    font-size: 9vw;
    line-height: 10vw;
    padding-top: 10%;
    white-space: normal;
    width: 20vw;
  }

  .graphic {
    position: relative;
    bottom: 10vw;
    height: 100vw;
    transform: rotate(-90deg);
    margin-left: 50vw;
  }
}
