.PH {
    font-size: 400px;
}

.testdiv {
    width: 100vw;
    height: 100vh;
    background-color: black;
    overflow-y: scroll;
    font-size: 20vh;
}

.card-container-flex {
    height: 100vh;
    width: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-wrapper {
    height: 100vh;
    width: 50vh;
    margin: 0px;
    padding: 0px;
}

.link-wrapper-mobile {
    width: 100vw;
    margin: 0px;
    padding: 0px;
}

.card-img {
    margin-top: 20vh;
    width: 30vh;
    height: auto;
}

.card-img-mobile {
    width: 20vh;
    height: auto;
}

.title-bkgnd {
    background-color: black;
    width: 50vh;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
}

.title-bkgnd-mobile {
    background-color: black;
    width: 10vh;
    height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0%;
}

.title-text {
    font-family: 'Space Mono 700';
    font-weight: 700;
    font-size: 7.5vh;
    line-height: 5vh;
    text-transform: uppercase;
}

.intro-navbar {
    position: fixed;
    z-index: 2;
    margin-left: 3%;
    padding-top: 2%;
    width: 42vh;
}

@media (max-width: 650px) or (max-device-width: 650px) {
    .intro-navbar {
        position: fixed;
        left: 90%;
        transform: translateX(-50%);
        width: auto;
    }
}

@media only screen and (max-device-width: 650px) {
    .teams-navbar {
        position: fixed;
        left: 90%;
        transform: translateX(-50%);
        width: auto;
    }
}