.team-card-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
  transition: all 0.3s ease-in-out;
  box-shadow: -1rem 1rem white;
  border: 2px solid black;
}

.client-border {
  border: 2px solid white;
}

.team-card-container:hover:not(.expanded) {
  box-shadow: 0 0 !important;
  transition: all 0.3s ease-in-out;
}

.team-header-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
}

.client-header {
  background: black !important;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.interactive-button {
  display: flex;
  width: 100%;
  height: 4rem;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: black;
  padding: 10px;
  padding-left: 20px;
  gap: 10px;
  border: 0;
}

.expanded-container {
  display: flex;
  flex-direction: column;
  background-color: black;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.team-card-container.expanded .expanded-container {
  max-height: 1000px;
  padding: 10px 20px;
}

.team-header {
  font-family: 'Space Mono 700';
  color: black;
  font-size: 3rem;
  line-height: 100%;
  text-transform: uppercase;
}


.team-subheader {
  color: white;
  font-family: 'Space Mono 700';
  font-size: 2em;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.paragraph {
  color: black;
  font-size: 1em;
  font-family: "Outfit";
}