html,
body {
  height: 100%;
  margin: 0px;
}

.flex-parent {
  height: 35vh;
  display: flex;
}

.flex-child {
  flex: 1;
  /* takes care of the width / height */
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.culture-general-img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img1 {
  -webkit-mask-image: linear-gradient(to right, transparent 15%, black 85%);
  mask-image: linear-gradient(to right, transparent 15%, black 85%);
}

.img2 {
  -webkit-mask-image: linear-gradient(to right, transparent 15%, black 85%);
  mask-image: linear-gradient(to right, transparent 15%, black 85%);
}

.img3 {
  -webkit-mask-image: linear-gradient(to right, transparent 15%, black 85%);
  mask-image: linear-gradient(to right, transparent 15%, black 85%);
}

@media (max-width: 650px) or (max-device-width: 650px) {
  .collage-container {
    display: flex;
    height: 280vw;
  }

  .collage-col-1-container {
    display: flex;
    flex-direction: column;
    width: 50vw;
  }

  .collage-col-2-container {
    display: flex;
    flex-direction: column;
    width: 50vw;
  }

  .img-gradient {
    -webkit-mask-image: linear-gradient(to bottom, transparent 15%, black 85%);
    mask-image: linear-gradient(to bottom, transparent 15%, black 85%);
    object-fit: cover;
    width: 50vw;
  }

  .img-gradient-long {
    -webkit-mask-image: linear-gradient(to bottom, transparent 15%, black 85%);
    mask-image: linear-gradient(to bottom, transparent 15%, black 85%);
    object-fit: cover;
    width: 50vw;
    height: 65vw;
  }

  .img-no-gradient {
    object-fit: cover;
    width: 50vw;
  }

  .img-no-gradient-long {
    object-fit: cover;
    width: 50vw;
    height: 65vw;
  }
}
