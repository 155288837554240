.tag-container {
    display: flex;
    height: 2rem;
    padding: 4px 7px;
    max-width: fit-content;
    align-items: center;
    background: #FFF;
}

.tag-text {
    color: black;
    font-size: 1em;
    font-family: "Outfit";
}