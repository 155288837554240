span {
  font-family: 'Space Mono 700';
  font-size: 2.46vh;
  line-height: 3.69vh;
  /* text-transform: capitalize; */
}

.bar-size {
  height: max(50px, 7vmin);
  width: max(250px, 35vmin);
}

#nav-contents {
  border: 1px black solid;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

#gen-nav-logo {
  cursor: pointer;
}

#nav-all {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  padding: 2rem;
}

.menu-hover {
  cursor: pointer;
  color: black;
}

.menu-hover:hover {
  color: white;
  -webkit-text-stroke: 0.5px black;
}

.menu-navbar {
  width: fit-content;
  max-width: auto;
  padding-left: 2px;
  padding-right: 2px;
  height: auto;
}

.menu-font-style {
  font-family: 'Space Mono 700';
  font-size: 12vw;
  line-height: 100%;
}

.header-font-style {
  font-family: 'Space Mono 700';
  font-size: 4.5vw;
  line-height: 128%;
  font-size: fit-content;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.header-font-style:hover {
  background-color: black;
  color: #ffbf3c !important;
}

.header-font-style:hover,
.header-font-style:active {
  cursor: pointer;
}

.h-line {
  position: absolute;
  height: 1px;
  background-color: black;
}

.v-line {
  position: absolute;
  width: 1px;
  background-color: black;
}

.modal-header {
  padding: 0 0 0 0;
  border-bottom: none;
}

.menu-font-style {
  font-size: 15vw;
  text-transform: uppercase;
}

.newsletter-font {
  font-size: 5vw;
  font-family: 'Space Mono 700';
  line-height: 100%;
  text-transform: uppercase;
  transform: rotate(270deg);
}

@media (max-device-width: 650px) {
  .wrapper-nav {
    width: 5vh;
    height: 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: white;
    border: 1px solid black;
  }
  .hamburger-mobile {
    transform: scale(2.5);
  }
}

@media (max-width: 650px) or (max-device-width: 650px) {
  .modal-header {
    padding: 2.5% 2.5% 0 0;
  }
  .header-font-style {
    font-size: 10vw;
  }
  .newsletter-font {
    font-size: 12.5vw;
    transform: none;
  }
  .bar-size {
    height: 5vh;
    width: 5vh;
  }
}
